var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DashboardCardTypePieChart",class:{
    'DashboardCardTypePieChart--flat': _vm.flat,
  }},[_c('div',{staticClass:"DashboardCardTypePieChart__title",domProps:{"textContent":_vm._s(_vm.card.name)}}),_c('div',{staticClass:"d-flex align-center",class:{
      'justify-center': !_vm.chartData.labels.length,
      'justify-space-between': !!_vm.chartData.labels.length,
    },staticStyle:{"width":"100%"}},[_c('div',{staticClass:"DashboardCardTypePieChart__width-guard"},[_c('VueChart',{ref:"chart",staticClass:"DashboardCardTypePieChart__canvas",attrs:{"type":"doughnut","data":_vm.chartData,"options":_vm.chartOptions,"width":150,"height":150},on:{"redrawn":_vm.hideHiddenData},nativeOn:{"click":function($event){$event.preventDefault();},"mouseenter":function($event){_vm.chartHovered = true},"mouseleave":function($event){_vm.chartHovered = false; _vm.hoveredIx = null}}})],1),(_vm.chartData.labels.length)?_c('div',{staticClass:"DashboardCardTypePieChart__legend",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mouseenter":function($event){_vm.chartHovered = true},"mouseleave":function($event){_vm.chartHovered = false}}},_vm._l((_vm.chartData.labels),function(lbl,ix){return _c('router-link',{key:lbl + '\b' + ix,staticClass:"DashboardCardTypePieChart__legend-item",class:{
          'DashboardCardTypePieChart__legend-item--hidden':
            _vm.isDataHidden(ix),
          'DashboardCardTypePieChart__legend-item--muted':
            _vm.hoveredIx != null && _vm.hoveredIx !== ix,
        },attrs:{"to":_vm.getRouteForPieIndex(ix)},nativeOn:{"mouseenter":function($event){_vm.hoveredIx = ix},"mouseleave":function($event){_vm.hoveredIx = null}}},[_c('span',{staticClass:"DashboardCardTypePieChart__legend-item-square",style:({ backgroundColor: _vm.getColor(_vm.sortedValuePairs[ix][0]) })}),_c('b',{staticClass:"DashboardCardTypePieChart__legend-item-percent",domProps:{"textContent":_vm._s(_vm.getPercentForIx(ix, { round: true }) + '%')}}),_c('span',{staticClass:"DashboardCardTypePieChart__legend-item-label",domProps:{"textContent":_vm._s(lbl)}})])}),1):_vm._e()]),(_vm.card.query.query)?_c('FilterLegend',{staticClass:"DashboardCardTypePieChart__footer",attrs:{"project-id":_vm.projectId,"query":_vm.card.query.query}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }