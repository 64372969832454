<template>
  <div class="DashboardCardTypeCounter">
    <div
      class="DashboardCardTypeCounter__name"
    >
      {{ card.name }}
      <sup
        v-if="changes.hasOwnProperty('name')"
        v-text="'*'"
      />
    </div>

    <div
      class="DashboardCardTypeCounter__counter"
      :class="{
        'DashboardCardTypeCounter__counter--muted':
          card.value == null ||
          (!preview && changes.hasOwnProperty('query'))
      }"
      v-text="card.value == null ? '0' : card.value.query"
    />

    <div class="DashboardCardTypeCounter__progress">
      <v-progress-linear
        :value="percent || 0"
        :height="8"
        :color="color.color"
        :indeterminate="percent == null"
        background-color="#A9A7BF"
        background-opacity="0.3"
        rounded
      />
    </div>

    <FilterLegend
      v-if="card.query.query"
      :project-id="projectId"
      :query="card.query.query"
      class="DashboardCardTypeCounter__footer"
    />
  </div>
</template>

<script>
import * as R from 'ramda'

import { BG, COLORS, COLORS_LOOKUP } from '../constants'
import { randomChoice } from '../helpers'

import FilterLegend from './FilterLegend'

export default {
  name: 'DashboardCardTypeCounter',

  components: {
    FilterLegend,
  },

  props: {
    // cards belong to a project or cross-project dashboard (null)
    projectId: { type: String, default: null },
    // see `DashboardCard:data:model`
    card: { type: Object, required: true },
    // see `DashboardCard:computed:changes`
    changes: { type: Object, required: true },

    // some parent (`DashboardCard.vue`) state
    edit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
  },

  computed: {
    // card styles
    color() {
      const { card: { metaData } } = this
      const color = metaData && metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : randomChoice(COLORS)
      return COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card: { metaData } } = this
      const pattern = metaData && metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : randomChoice(Object.keys(BG))
      return BG[patternKey]
    },

    totalIssues() {
      const { $store, projectId } = this
      return $store.state.entities.issue.$total[projectId || null]
    },

    percent() {
      const { totalIssues, card: { value } } = this
      if (totalIssues == null || value == null) return null
      if (totalIssues === 0 || value.query === 0) return 0
      return (value.query / totalIssues) * 100
    },
  },
}
</script>

<style lang="sass" scoped>
.DashboardCardTypeCounter
  padding: 16px 24px 21px
  height: 100%
  min-height: 140px
  display: flex
  flex-direction: column

  &__name
    overflow: hidden
    text-overflow: ellipsis
    font-weight: 500
    font-size: 14px
    line-height: 120%

  &__counter
    font-weight: 700
    font-size: 36px
    line-height: 36px
    text-decoration: inherit
    transition: all 100ms ease-out
    margin: auto 0

    &#{ & }--muted
      opacity: .3
    > a
      color: inherit
      text-decoration: none

  // should we highlight counter on card hover?
  // &:hover, &:active, &:focus
  //   .DashboardCardTypeCounter__counter:not(.DashboardCardTypeCounter__counter--muted)
  //     color: #007AE6

  &__progress
    margin: auto 0

  &__footer
    margin-top: 10px
</style>
