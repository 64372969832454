<template>
  <span class="FilterLegendStatus">
    <v-tooltip
      top
    >
      <template #activator="{ on, attrs }">
        <span
          class="FilterLegendStatus__dots"
          v-bind="attrs"
          v-on="on"
        >
          <span
            v-for="status in issueStatus"
            :key="status.name"
            class="FilterLegendStatus__dot"
            :class="{
              'FilterLegendStatus__dot--active': isActive(status.name)
            }"
            :style="{ background: isActive(status.name) && getColor(status.name) }"
          />
        </span>
      </template>

      <span v-text="tooltipText" />
    </v-tooltip>
  </span>
</template>

<script>
import { helpers as sharedHelpers } from '@hexway/shared-front'

import { PREDEFINED_STATUSES, PREDEFINED_STATUS_LOOKUP } from '../constants'

export default {
  name: 'FilterLegendStatus',

  props: {
    projectId: { type: String, default: null },

    // Status example:
    //  [
    //    { "name": "Re-opened", "id": "reopened" },
    //    { "name": "In progress", "id": "in_progress" },
    //  ]
    status: { type: Array, required: true },
  },

  computed: {
    issueStatus() {
      const { $store, projectId } = this
      return projectId
        ? $store.getters['$issueStatus/getList'](projectId)
        : PREDEFINED_STATUSES
    },
    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId
        ? $store.getters['$issueStatus/getLookup'](projectId)
        : PREDEFINED_STATUS_LOOKUP
    },

    tooltipText() {
      const { status } = this
      // return `Status: ${status.map(s => s.displayName).join(', ')}`
      return status.map(s => s.displayName).join(', ')
    },
  },

  methods: {
    getColor(statusKey) {
      const color = this.issueStatusLookup[statusKey]?.color || 'grey'
      return sharedHelpers.vuetifyColorToHex(this.$vuetify, color)
    },

    isActive(statusKey) {
      return this.status.map(s => s.name).includes(statusKey)
    },
  },
}
</script>

<style lang="sass" scoped>
.FilterLegendStatus
  display: inline-block

  &__dots
    display: inline-flex
    background: rgba(255, 255, 255, .75)
    border-radius: 4px
    height: 15px
    align-items: center
    padding: 0 4px

  &__dot
    display: inline-block
    width: 0
    height: 8px
    background: #8492A6
    border-radius: 100%
    transform: scale(0)
    transition: all 300ms ease-in

    &--active
      width: 8px
      transform: none

    &--active + &
      margin-left: 4px
</style>
