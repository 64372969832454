<template>
  <div class="DashboardCardTypeChecklist">
    <div class="DashboardCardTypeChecklist__big-counter">
      <div class="percentage">
        <span class="percentage-header">{{ $t('dashboard.Done') }}</span>
        <span>{{ percentForBigCounter }}%</span>
      </div>
    </div>

    <div
      v-if="checklistById"
      class="DashboardCardTypeChecklist__name"
    >
      {{ card.name }}
      <sup
        v-if="changes.hasOwnProperty('name')"
        v-text="'*'"
      />
    </div>

    <div
      v-if="card.value != null"
      class="DashboardCardTypeChecklist__counter"
      :class="{
        'DashboardCardTypeChecklist__counter--muted':
          card.value == null ||
          (!preview && changes.hasOwnProperty('query'))
      }"
    >
      <span v-if="card.value['not_done'] === 0">
        {{ card.value.done }} / {{ card.value.done }}
      </span>
      <span v-else>
        {{ card.value.done }} / {{ card.value['not_done'] + card.value.done }}
      </span>
    </div>

    <div
      v-if="card.value != null"
      class="DashboardCardTypeChecklist__progress"
    >
      <v-progress-linear
        :value="percent || 0"
        height="100vh"
        color="#C0D9FF"
        :indeterminate="percent == null"
        background-color="#FFFFFF"
        background-opacity="0.3"
      />
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'

import { BG, COLORS, COLORS_LOOKUP } from '../constants'
import { randomChoice } from '../helpers'

export default {
  name: 'DashboardCardTypeChecklist',

  props: {
    // cards belong to projects
    projectId: { type: String, required: true },
    // see `DashboardCard:data:model`
    card: { type: Object, required: true },
    // see `DashboardCard:computed:changes`
    changes: { type: Object, required: true },

    // some parent (`DashboardCard.vue`) state
    edit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
  },

  computed: {
    checklistById() {
      const { $store, card } = this
      return $store.state.checklist.byId[card.query.nodeID]
    },

    // card styles
    color() {
      const { card: { metaData } } = this
      const color = metaData && metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : randomChoice(COLORS)
      return COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card: { metaData } } = this
      const pattern = metaData && metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : randomChoice(Object.keys(BG))
      return BG[patternKey]
    },

    totalCheckists() {
      const { card: { value } } = this
      return value?.done + value?.not_done
    },

    percentForBigCounter() {
      const { card: { value }, totalCheckists } = this
      return Math.round((value?.done / totalCheckists) * 100) || 0
    },

    percent() {
      const { card: { value }, totalCheckists } = this
      return (value.done / totalCheckists) * 100 || 5
    },
  },
}
</script>

<style lang="sass" scoped>
.DashboardCardTypeChecklist
  padding: 16px 24px 21px
  height: 100%
  min-height: 100px
  max-height: 100px
  min-width: calc((100vw - 480px) / 3)
  max-width: calc((100vw - 480px) / 3)
  display: inline-grid
  align-items: center
  position: relative
  overflow: hidden

  // Temporary crutch until the designer comes up with a decision on the cards
  @media only screen and (min-width: 1000px) and (max-width: 1919px)
    min-width: calc((100vw - 460px) / 2)
    max-width: calc((100vw - 460px) / 2)

  @media only screen and (min-width: 2560px)
    min-width: calc((100vw - 500px) / 4)
    max-width: calc((100vw - 500px) / 4)

  &__big-counter
    position: absolute
    font-size: 1.6rem
    font-weight: 500
    width: 25%

    .percentage
      display: flex
      justify-content: center

      &-header
        color: #5DACF4
        font-weight: 900
        font-size: .7rem
        line-height: 14px
        letter-spacing: 4px
        position: absolute
        top: -8px

  &__name
    overflow: hidden
    text-overflow: ellipsis
    font-weight: 500
    font-size: 14px
    line-height: 120%
    position: relative
    z-index: 1
    margin-left: 27%

  &__counter
    font-weight: 700
    font-size: 12px
    line-height: 36px
    text-decoration: inherit
    transition: all 100ms ease-out
    margin: auto 0
    position: relative
    z-index: 1
    padding-left: 27%
    color: rgba(0, 0, 0, 0.38)

    &#{ & }--muted
      opacity: .3
    > a
      color: inherit
      text-decoration: none

  &__progress
    position: absolute
    top: 0
    width: 75%
    left: 25%
    z-index: 0
    margin: auto 0
    border-radius: 2px

  &__footer
    margin-top: 10px
</style>
