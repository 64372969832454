<template>
  <v-card
    class="DashboardCard"
    :color="highlighted ? 'rgba(16, 102, 230, .075)' : 'white'"
    :class="{
      'DashboardCard--edit': edit,
      'DashboardCard--disabled': disabled,
      'DashboardCard--preview': preview,
      'DashboardCard--counter': card.cardType === CARD_TYPE.counter,
      'DashboardCard--checklist': card.cardType === CARD_TYPE.checklistStatus,
      'DashboardCard--a-of-b': card.cardType === CARD_TYPE.aOfB,
      'DashboardCard--pie-chart': card.cardType === CARD_TYPE.pieChart,
      // pie-chart cards are flat and combined into one big flat card
      // unless it is in a preview mode
      'DashboardCard--flat': isFlat,
      'DashboardCard--table': card.cardType === CARD_TYPE.table,
    }"
    :to="(edit || preview) ? null : cardRoute"
    :ripple="(edit || preview || card.cardType === CARD_TYPE.aOfB)
      ? false
      : { class: 'DashboardCard__purple-ripple' }"
    :disabled="disabled && !preview"
  >
    <section class="DashboardCard__content">
      <v-progress-circular
        v-if="model.value == null && !edit"
        class="DashboardCard__content-loading"
        indeterminate
        :size="128"
      />

      <!-- Simple counter type of card -->
      <DashboardCardTypeCounter
        v-if="model.cardType === CARD_TYPE.counter"
        :project-id="projectId"
        :card="model"
        :changes="changes"
        :disabled="disabled"
        :edit="edit"
        :preview="preview"
        style="min-width: 100%"
      />
      <!-- A/B counter type of card -->
      <DashboardCardTypeAOfB
        v-else-if="model.cardType === CARD_TYPE.aOfB"
        :project-id="projectId"
        :card="model"
        :changes="changes"
        :disabled="disabled"
        :edit="edit"
        :preview="preview"
        style="min-width: 100%"
      />
      <!-- pie chart -->
      <DashboardCardTypePieChart
        v-else-if="model.cardType === CARD_TYPE.pieChart"
        :project-id="projectId"
        :card="model"
        :changes="changes"
        :flat="isFlat"
        :style="preview ? { pointerEvents: 'none' } : null"
        style="min-width: 100%"
      />
      <!-- A card with a table -->
      <DashboardCardTypeTable
        v-else-if="model.cardType === CARD_TYPE.table"
        :project-id="projectId"
        :card="model"
        :changes="changes"
        :disabled="disabled"
        :edit="edit"
        :preview="preview"
        :highlighted="highlighted"
        style="min-width: 100%"
      />
      <!-- A card with a checklist -->
      <DashboardCardTypeChecklist
        v-else-if="model.cardType === CARD_TYPE.checklistStatus"
        :project-id="projectId"
        :card="model"
        :changes="changes"
        :disabled="disabled"
        :edit="edit"
        :preview="preview"
      />

      <pre
        v-else
        v-text="$t('dashboard.UnrecognizedCardType')"
      />
    </section>

    <!-- card actions -->
    <section
      v-if="edit && !preview"
      class="DashboardCard__actions"
    >
      <v-hover v-slot="{ hover }">
        <v-btn
          class="DashboardCard__button DashboardCard__edit-button"
          icon
          :disabled="disabled"
          :to="editRoute"
        >
          <v-icon
            :color="hover ? 'primary' : null"
            v-text="'$edit'"
          />
        </v-btn>
      </v-hover>

      <v-hover v-slot="{ hover }">
        <v-btn
          class="DashboardCard__button DashboardCard__delete-button"
          icon
          :disabled="disabled"
          @click.native.stop.prevent="$emit('delete')"
        >
          <v-icon
            :color="hover ? 'primary' : null"
            size="18"
            v-text="'$trash-can'"
          />
        </v-btn>
      </v-hover>

      <v-hover v-slot="{ hover }">
        <v-btn
          tag="div"
          class="DashboardCard__button DashboardCard__drag-handle"
          icon
          :disabled="disabled"
          :title="$t('dashboard.DragToChangeOrderM')"
        >
          <v-icon
            :color="hover ? 'primary' : null"
            size="14"
            v-text="'$drag'"
          />
        </v-btn>
      </v-hover>
    </section>
  </v-card>
</template>

<script>
import * as R from 'ramda'

import {
  CARD_TYPE,
} from '../constants'

import DashboardCard from '../store/orm/dashboardCard'

import DashboardCardTypeAOfB from './DashboardCardTypeAOfB'
import DashboardCardTypeCounter from './DashboardCardTypeCounter'
import DashboardCardTypePieChart from './DashboardCardTypePieChart'
import DashboardCardTypeTable from './DashboardCardTypeTable'
import DashboardCardTypeChecklist from '@/components/DashboardCardTypeChecklist'

export default {
  name: 'DashboardCard',

  components: {
    DashboardCardTypeAOfB,
    DashboardCardTypeCounter,
    DashboardCardTypePieChart,
    DashboardCardTypeTable,
    DashboardCardTypeChecklist,
  },

  model: {
    prop: 'card',
    event: 'change',
  },

  props: {
    // cards belong to projects
    projectId: { type: String, default: null },
    // `DashboardCardSchema`, see also store/schema:`Card`
    card: { type: Object, required: true },
    // `Dashboard` controls the edit state of its cards
    edit: { type: Boolean, default: false },
    // disable all behaviour
    disabled: { type: Boolean, default: false },
    // has some background and scrolls to itself on being rendered
    highlighted: { type: Boolean, default: false },
    // for some styles while inside a create/edit modal dialog
    preview: { type: Boolean, default: false },
  },

  data: () => ({
    CARD_TYPE,

    model: null,
  }),

  computed: {
    isFlat() {
      const { preview, card, edit } = this
      return !preview && !edit && card.cardType === CARD_TYPE.pieChart
    },

    referenceCard() {
      const { card } = this
      return card.id ? DashboardCard.find(card.id) : null
    },

    cardRoute() {
      const { projectId, card } = this

      if ([CARD_TYPE.counter, CARD_TYPE.aOfB, CARD_TYPE.pieChart].includes(card.cardType)) {
        return projectId
          ? {
            name: 'ProjectCardIssueList',
            params: { projectId, cardId: card.id },
          }
          : {
            name: 'CardIssueList',
            params: { cardId: card.id },
          }
      }

      if ([CARD_TYPE.checklistStatus].includes(card.cardType)) {
        if (!projectId) {
          throw new Error('Programming error: no project id for link to project checklists')
        }
        return {
          name: 'ChecklistsListChecklist',
          params: { projectId, checklistId: card.query.nodeID },
          query: { filter: card.query.nodeID },
        }
      }
      return null
    },

    // what has changed? (comparing `model` to `referenceCard`)
    changes() {
      const { model, referenceCard } = this

      if (!referenceCard) return model

      return R.pipe(
        R.toPairs,
        R.reject(([key, value]) => R.equals(referenceCard[key], value)),
        R.fromPairs,
      )(model)
    },

    editRoute() {
      const { card, projectId, $route } = this
      const params = projectId ? { projectId } : {}
      const query = { cardId: card.id, back: $route.fullPath }

      if (card?.query?.nodeID) query.checklistId = card.query.nodeID

      return {
        name: projectId ? 'ProjectEditCard' : 'EditCard',
        params,
        query,
      }
    },
  },

  watch: {
    // v-model (:card + @change)
    card: {
      handler(card) { this.model = R.clone(card) },
      immediate: true,
      deep: true,
    },
    model: {
      handler(model) {
        if (R.equals(model, this.card)) return
        this.$emit('change', model)
      },
      deep: true,
    },

    highlighted: {
      async handler() {
        if (this.highlighted) {
          // bunch-a hacks to scroll highlighted card into view
          await this.$nextTick()
          if (this.highlighted) this.scrollIntoView()
          await new Promise(resolve => setTimeout(resolve, 200))
          if (this.highlighted) this.scrollIntoView()
          await new Promise(resolve => setTimeout(resolve, 400))
          if (this.highlighted) this.scrollIntoView()
          await new Promise(resolve => setTimeout(resolve, 600))
          if (this.highlighted) this.scrollIntoView()
        }
      },
      immediate: true,
    },
  },

  methods: {
    scrollIntoView() {
      // eslint-disable-next-line no-unused-expressions
      this.$el.scrollIntoView({ block: 'nearest' })
    },

    // for jest unit tests
    async _depsResolved() {
      if (this.card && this.card.cardType === CARD_TYPE.pieChart) {
        await import('./VueChart')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.DashboardCard
  display: inline-block
  position: relative
  transform-origin: center
  background-position: center
  background-repeat: repeat
  color: #3C3A52

  &--flat
    box-shadow: none !important
    border-radius: 0

  &:not(&--flat)
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2) !important

  ::v-deep &__purple-ripple
    color: rgba(91, 77, 255, 0.35)

  &--counter
    flex: 1 0 auto
    min-width: 208px !important
    max-width: 576px
    max-height: 140px

  &--checklist
    //flex: 1 0 auto
    min-width: 208px !important
    max-width: 576px
    max-height: 140px

  &--a-of-b
    flex: 1 0 auto
    min-width: calc((100vw - 480px) / 3)
    max-width: calc((100vw - 480px) / 3)
    // display: flex
    // justify-content: stretch

  &--pie-chart
    flex: 1 0 300px
    max-width: 377px

  &--pie-chart &__content
    justify-content: flex-start

  &--table
    flex: 0 0 auto

  &:not(&--edit)
    transition: all 100ms ease-in
    overflow: hidden

  &:not(&--edit):not(&--flat):hover
    box-shadow: 0 8px 16px rgba(0, 0, 0, .12) !important

  &--edit:hover
    box-shadow: none
    cursor: default

  &:not(&--edit)
    &:before, &::before
      display: none !important

  &__content
    display: flex
    justify-content: stretch
    align-items: stretch
    position: relative
    z-index: 2
    height: 100%

    &-loading
      position: absolute
      top: 50%
      left: 50%
      transform: translateY(-50%) translateX(-50%)
      z-index: 2

  &--edit &__content
    &, & *
      pointer-events: none !important

  &__actions
    position: absolute
    z-index: 2
    top: 0
    right: 0
    display: flex
    flex-direction: column
    background: rgba(255, 255, 255, 1)
    height: 100%
    width: 40px
    padding-left: 2px
    border-radius: 0 $card-border-radius $card-border-radius 0 !important
    border-left: 1px solid rgba(0, 0, 0, .12)
    opacity: 0
    transition: opacity 150ms ease-in

  &:hover &__actions, &:active &__actions, &:focus &__actions, &:focus-within &__actions
    opacity: 1

  &__edit-button
    margin-top: 5px

  &__delete-button
    margin-top: 5px

  &__drag-handle
    margin-top: auto

  &__info-action
    z-index: 2
    top: 2px
    right: 2px

  &__button
    opacity: .6
    transition: opacity 100ms ease-out
    cursor: pointer
    &:hover, &:active, &:focus
      opacity: 1
</style>
