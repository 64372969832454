<template>
  <span class="FilterLegendScoreBadges">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <span
          class="FilterLegendScoreBadges__badges"
          v-bind="attrs"
          v-on="on"
        >
          <ColorScore
            v-for="score in scores"
            :key="score.value"
            :score="score.value"
            denser
            :info-label="$t('dashboard.scoreInfoLetter')"
            :critical-label="$t('dashboard.scoreCriticalLetter')"
            :high-label="$t('dashboard.scoreHighLetter')"
            :medium-label="$t('dashboard.scoreMediumLetter')"
            :low-label="$t('dashboard.scoreLowLetter')"
            class="FilterLegendScoreBadges__badge"
          />
        </span>
      </template>

      <span v-text="tooltipText" />
    </v-tooltip>
  </span>
</template>

<script>
import ColorScore from './ColorScore'
import i18n from '../i18n'

export default {
  name: 'FilterLegendScoreBadges',

  components: {
    ColorScore,
  },

  props: {
    projectId: { type: String, default: null },

    scoreTypeLabel: { type: String, default: i18n.t('dashboard.TotalScore') },

    // scores example:
    // [
    //   { value: 1, displayValue: 'Low', ... },
    //   ...,
    // ]
    scores: { type: Array, required: true },
  },

  computed: {
    tooltipText() {
      const { scoreTypeLabel, scores } = this
      return `${scoreTypeLabel}: ${scores.map(s => s.displayValue).join(', ')}`
    },
  },
}
</script>

<style lang="sass" scoped>
.FilterLegendScoreBadges
  display: inline-block

  &__badges
    display: inline-block

  &__badge
    & + &
      margin-left: 2px
</style>
