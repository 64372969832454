<template>
  <span
    v-if="hasFilters"
    class="FilterLegend"
  >
    <FilterLegendStatus
      v-if="parsedQuery.status"
      :project-id="projectId"
      :status="parsedQuery.status"
      class="FilterLegend__status"
    />

    <FilterLegendScoreBadges
      v-if="parsedQuery.totalScore"
      :project-id="projectId"
      :scores="parsedQuery.totalScore"
      class="FilterLegend__total-score"
    />

    <span
      v-if="moreTooltipText"
      class="FilterLegend__more"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            :size="16"
            v-bind="attrs"
            v-on="on"
            v-text="'mdi-dns-outline'"
          />
        </template>

        <span
          class="d-inline-block"
          style="white-space: pre-wrap; max-width: 300px"
          v-text="moreTooltipText"
        />
      </v-tooltip>
    </span>

    <FilterLegendScoreDots
      v-if="parsedQuery.probabilityScore"
      :scores="parsedQuery.probabilityScore"
      :score-type-label="$t('dashboard.ProbabilityScore')"
      :caption-letter="$t('dashboard.probabilityScoreLetter')"
      class="FilterLegend__probability"
    />

    <FilterLegendScoreDots
      v-if="parsedQuery.criticalityScore"
      :scores="parsedQuery.criticalityScore"
      :score-type-label="$t('dashboard.CriticalityScore')"
      :caption-letter="$t('dashboard.criticalityScoreLetter')"
      class="FilterLegend__criticality"
    />
  </span>
</template>

<script>
import * as R from 'ramda'

import database from '../store/orm'
import { ISSUE_FIELD_TYPE } from '../constants'
import { parseFilterQuery, parseCrossProjectFilterQuery } from '../helpers'

import FilterLegendScoreBadges from './FilterLegendScoreBadges'
import FilterLegendScoreDots from './FilterLegendScoreDots'
import FilterLegendStatus from './FilterLegendStatus'

const FIXED_FIELDS = Object.freeze([
  'probabilityScore',
  'criticalityScore',
  'totalScore',
  'status',
  'ips',
  'hostnames',
  'ports',
  'protocols',
  'paths',
  'optionals',
])

export default {
  name: 'FilterLegend',

  components: {
    FilterLegendScoreBadges,
    FilterLegendScoreDots,
    FilterLegendStatus,
  },

  props: {
    projectId: { type: String, default: null },
    query: { type: String, required: true }, // e.g.: 'probability=1&status=closed'
  },

  computed: {
    // status dictionary from projects settings
    issueStatus() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getList'](projectId)
    },
    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getLookup'](projectId)
    },
    fieldsLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/fieldsLookup'](projectId, true)
    },
    filterableFields() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/filterableFields'](projectId)
    },

    restOfFilterableFields() {
      const { filterableFields } = this
      return filterableFields && filterableFields.filter(f => !FIXED_FIELDS.includes(f.name))
    },

    parsedQuery() {
      const { projectId, issueStatus, issueStatusLookup, fieldsLookup, query } = this
      if (projectId) {
        if (!issueStatus || !issueStatusLookup || !fieldsLookup) return null
        return parseFilterQuery(issueStatus, issueStatusLookup, fieldsLookup, query)
      }

      return parseCrossProjectFilterQuery(database, query)
    },

    parsedRestFields() {
      const { parsedQuery, restOfFilterableFields } = this
      if (!parsedQuery || !restOfFilterableFields) return null
      return R.pick(
        restOfFilterableFields.map(R.prop('name')),
        parsedQuery,
      )
    },

    // has non-redundant filters
    hasFilters() {
      const { parsedQuery } = this
      return parsedQuery && Object.keys(parsedQuery).length > 0
    },

    moreTooltipText() {
      const { parsedQuery, parsedRestFields, fieldsLookup } = this
      if (!parsedQuery || (
        !parsedQuery.overdue &&
        !parsedQuery.isCompleted &&
        !parsedQuery.ips &&
        !parsedQuery.hostnames &&
        !parsedQuery.ports &&
        !parsedQuery.protocols &&
        !parsedQuery.paths &&
        !parsedQuery.optionals &&
        !parsedQuery.project &&
        !parsedQuery.groups &&
        !Object.keys(parsedRestFields || {}).length
      )) return null

      const lines = []

      if (parsedQuery.overdue) {
        lines.push(`${this.$t('dashboard.CardSla')} ${parsedQuery.overdue.map(R.prop('displayValue')).join(', ')}`)
      }
      if (parsedQuery.isCompleted) {
        lines.push(`${this.$t('dashboard.CardCompleted')} ${parsedQuery.isCompleted.map(R.prop('displayValue')).join(this.$t('dashboard.CardCompletedConjunction'))}`)
      }
      if (parsedQuery.ips) {
        lines.push(`${this.$t('dashboard.CardIp')} ${parsedQuery.ips.join(', ')}`)
      }
      if (parsedQuery.hostnames) {
        lines.push(`${this.$t('dashboard.CardHostname')} ${parsedQuery.hostnames.join(', ')}`)
      }
      if (parsedQuery.ports) {
        lines.push(`${this.$t('dashboard.CardHostname')} ${parsedQuery.ports.join(', ')}`)
      }
      if (parsedQuery.protocols) {
        lines.push(`${this.$t('dashboard.CardHostname')} ${parsedQuery.protocols.join(', ')}`)
      }
      if (parsedQuery.project) {
        lines.push(`${this.$t('dashboard.CardProject')} ${parsedQuery.project.map(p => p.name).join(', ')}`)
      }
      if (parsedQuery.groups) {
        lines.push(`${this.$t('dashboard.CardGroup')} ${parsedQuery.groups.map(g => g.name).join(', ')}`)
      }
      if (Object.keys(parsedRestFields || {}).length) {
        for (const [fieldName, parsedValues] of Object.entries(parsedRestFields)) {
          const field = fieldsLookup[fieldName]
          const valuesPretty = field.type === ISSUE_FIELD_TYPE.BOOLEAN
            ? parsedValues
            : parsedValues.map(v => v.displayValue).join(', ')
          lines.push(`${field.displayName}: ${valuesPretty}`)
        }
      }

      return lines.join('\n')
    },
  },
}
</script>

<style lang="sass" scoped>
.FilterLegend
  display: inline-block
  line-height: 15px
  white-space: nowrap

  & > *
    vertical-align: middle

  &__total-score
    margin-left: 4px

  &__probability
    margin-left: 6px

  &__total-score + &__criticality
    margin-left: 6px

  &__more
    margin-left: 4px

  > *:first-child
    margin-left: 0 !important
</style>
