var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"DashboardCardTypeTable",class:{
    'DashboardCardTypeTable--disabled': _vm.disabled,
    'DashboardCardTypeTable--highlighted': _vm.highlighted,
  },style:({
    '--card-color': _vm.color && _vm.color.color,
    '--card-bg-img': _vm.bg && `url(${_vm.bg.image})`,
  })},[_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'white--text' }),expression:"{ class: 'white--text' }"}],staticClass:"DashboardCardTypeTable__title",attrs:{"to":_vm.preview ? '#' : _vm.baseRoute,"event":_vm.preview ? '_disable': 'click'}},[_c('span',{domProps:{"textContent":_vm._s(_vm.card.name)}}),_c('FilterLegend',{attrs:{"project-id":_vm.projectId,"query":_vm.card.query.query}})],1),_c('div',{staticClass:"DashboardCardTypeTable__table"},[_c('div',{staticClass:"DashboardCardTypeTable__column DashboardCardTypeTable__column--border-right flex-grow-0",on:{"mouseleave":function($event){_vm.hoverOverRow = null}}},[_c('router-link',{class:[
          'DashboardCardTypeTable__cell',
          'DashboardCardTypeTable__cell--bold',
          'DashboardCardTypeTable__cell--content-bottom',
          _vm.hoverOverRow === 0 ? 'DashboardCardTypeTable__cell--highlighted' : '',
          'justify-end',
        ],attrs:{"to":_vm.preview ? '#' : _vm.baseRoute},domProps:{"textContent":_vm._s(_vm.columnsCaption)},nativeOn:{"mouseenter":function($event){_vm.hoverOverRow = 0},"mouseleave":function($event){_vm.hoverOverRow = null}}}),_c('div',{class:[
          'DashboardCardTypeTable__cell',
          'DashboardCardTypeTable__cell--tiny',
          'DashboardCardTypeTable__cell--bold',
          'justify-end',
        ],domProps:{"textContent":_vm._s(_vm.rowsCaption)}}),_vm._l((_vm.tableItems),function(tableItem,rowIx){return _c('router-link',{key:`${tableItem.groupingValue}-${tableItem.yHeader}-${rowIx}`,staticClass:"DashboardCardTypeTable__cell justify-end",class:{
          'DashboardCardTypeTable__cell--highlighted':
            _vm.hoverOverRow === rowIx + 1,
          'DashboardCardTypeTable__cell--caption':
            rowIx === (_vm.tableItems.length - 1),
        },attrs:{"to":_vm.itemRoute(tableItem, { groupingValue: null })},nativeOn:{"mouseenter":function($event){_vm.hoverOverRow = rowIx + 1},"mouseleave":function($event){_vm.hoverOverRow = null}}},[(tableItem.groupingValue != null)?_c('DashboardCardTypeTableHeaderWidget',{attrs:{"project-id":_vm.projectId,"issue-prop":_vm.card.query.yAxis,"prop-value":tableItem.groupingValue,"status-text":tableItem.yHeader}}):_c('span',{domProps:{"textContent":_vm._s(tableItem.yHeader)}})],1)})],2),_c('div',{staticClass:"DashboardCardTypeTable__right",style:({ flexGrow: _vm.tableHeaders ? _vm.tableHeaders.length - 1 : 1 })},_vm._l(((_vm.tableHeaders && _vm.tableHeaders.slice(1))),function(tableHeader,colIx){return _c('div',{key:tableHeader.value,staticClass:"DashboardCardTypeTable__column"},[_c('router-link',{class:[
            'DashboardCardTypeTable__cell',
            colIx === (_vm.tableHeaders.length - 2) ? 'DashboardCardTypeTable__cell--caption' : '',
            'DashboardCardTypeTable__cell--content-bottom',
            _vm.hoverOverRow === 0 ? 'DashboardCardTypeTable__cell--highlighted' : '',
          ],attrs:{"to":_vm.itemRoute({ groupingValue: null }, tableHeader)},nativeOn:{"mouseenter":function($event){_vm.hoverOverRow = 0},"mouseleave":function($event){_vm.hoverOverRow = null}}},[(tableHeader.groupingValue != null)?_c('DashboardCardTypeTableHeaderWidget',{attrs:{"project-id":_vm.projectId,"issue-prop":_vm.card.query.xAxis,"prop-value":tableHeader.groupingValue,"status-text":tableHeader.text}}):_c('span',{domProps:{"textContent":_vm._s(tableHeader.text)}})],1),_c('div',{staticClass:"DashboardCardTypeTable__cell DashboardCardTypeTable__cell--tiny"}),(colIx === (_vm.tableHeaders.length - 2))?_vm._l((_vm.tableItems),function(tableItem,rowIx){return _c('router-link',{key:`${tableItem.groupingValue}-${tableHeader.yHeader}-${rowIx}`,staticClass:"DashboardCardTypeTable__cell DashboardCardTypeTable__cell--bold",class:{
              'DashboardCardTypeTable__cell--highlighted':
                _vm.hoverOverRow === rowIx + 1,
              'DashboardCardTypeTable__cell--border-top':
                rowIx > 0,
            },attrs:{"to":_vm.itemRoute(tableItem, tableHeader)},domProps:{"textContent":_vm._s(tableItem.total || '—')},nativeOn:{"mouseenter":function($event){_vm.hoverOverRow = rowIx + 1},"mouseleave":function($event){_vm.hoverOverRow = null}}})}):_vm._l((_vm.tableItems),function(tableItem,rowIx){return _c('router-link',{key:`${tableItem.groupingValue}-${tableHeader.yHeader}-${rowIx}`,staticClass:"DashboardCardTypeTable__cell",class:{
              'DashboardCardTypeTable__cell--highlighted':
                _vm.hoverOverRow === rowIx + 1,
              'DashboardCardTypeTable__cell--bold':
                rowIx === (_vm.tableItems.length - 1),
              'DashboardCardTypeTable__cell--border-top':
                rowIx > 0,
            },attrs:{"to":_vm.itemRoute(tableItem, tableHeader)},domProps:{"textContent":_vm._s(tableItem[tableHeader.value] || '—')},nativeOn:{"mouseenter":function($event){_vm.hoverOverRow = rowIx + 1},"mouseleave":function($event){_vm.hoverOverRow = null}}})})],2)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }