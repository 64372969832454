<template>
  <span
    class="DashboardCardTypeTableHeaderWidget"
    :class="`DashboardCardTypeTableHeaderWidget--${issueProp}`"
  >
    <span
      v-if="issueProp === 'status'"
      class="d-inline-flex align-center"
    >
      <v-avatar
        size="8"
        :color="issueStatusColor"
      />
      <span
        class="ml-1"
        v-text="statusText"
      />
    </span>
    <ColorScore
      v-else-if="['totalScore', 'probabilityScore', 'criticalityScore']
        .includes(issueProp)"
      :score="propValue"
      dense
      :info-label="$t('dashboard.scoreInfo')"
      :high-label="$t('dashboard.scoreHigh')"
      :medium-label="$t('dashboard.scoreMedium')"
      :low-label="$t('dashboard.scoreLow')"
      :critical-label="$t('dashboard.scoreCritical')"
    />
    <code v-else>
      {{ issueProp }}
      {{ propValue }}
    </code>
  </span>
</template>

<script>
import ColorScore from './ColorScore'

export default {
  name: 'DashboardCardTypeTableHeaderWidget',

  components: {
    ColorScore,
  },

  props: {
    projectId: { type: String, default: null },

    issueProp: {
      type: String,
      required: true,
    },

    propValue: {
      type: [String, Number],
      required: true,
    },

    statusText: { type: [String, Number], default: '' },
  },

  computed: {
    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getLookup'](projectId)
    },

    issueStatusColor() {
      const { issueStatusLookup, issueProp, propValue } = this
      if (issueProp !== 'status') return null
      return issueStatusLookup?.[propValue]?.color || 'grey'
    },
  },
}
</script>

<style lang="sass" scoped>
// .DashboardCardTypeTableHeaderWidget
</style>
