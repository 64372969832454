<template>
  <div
    class="DashboardCardTypePieChart"
    :class="{
      'DashboardCardTypePieChart--flat': flat,
    }"
  >
    <div
      class="DashboardCardTypePieChart__title"
      v-text="card.name"
    />

    <div
      class="d-flex align-center"
      :class="{
        'justify-center': !chartData.labels.length,
        'justify-space-between': !!chartData.labels.length,
      }"
      style="width: 100%"
    >
      <div class="DashboardCardTypePieChart__width-guard">
        <VueChart
          ref="chart"
          class="DashboardCardTypePieChart__canvas"
          type="doughnut"
          :data="chartData"
          :options="chartOptions"
          :width="150"
          :height="150"
          @click.native.prevent
          @mouseenter.native="chartHovered = true"
          @mouseleave.native="chartHovered = false; hoveredIx = null"
          @redrawn="hideHiddenData"
        />
      </div>

      <div
        v-if="chartData.labels.length"
        class="DashboardCardTypePieChart__legend"
        @click.stop.prevent
        @mouseenter="chartHovered = true"
        @mouseleave="chartHovered = false"
      >
        <router-link
          v-for="(lbl, ix) in chartData.labels"
          :key="lbl + '\b' + ix"
          class="DashboardCardTypePieChart__legend-item"
          :class="{
            'DashboardCardTypePieChart__legend-item--hidden':
              isDataHidden(ix),
            'DashboardCardTypePieChart__legend-item--muted':
              hoveredIx != null && hoveredIx !== ix,
          }"
          :to="getRouteForPieIndex(ix)"
          @mouseenter.native="hoveredIx = ix"
          @mouseleave.native="hoveredIx = null"
        >
          <!-- @click.prevent="toggleDataHidden(ix)" -->
          <span
            class="DashboardCardTypePieChart__legend-item-square"
            :style="{ backgroundColor: getColor(sortedValuePairs[ix][0]) }"
          />
          <b
            class="DashboardCardTypePieChart__legend-item-percent"
            v-text="getPercentForIx(ix, { round: true }) + '%'"
          />
          <span
            class="DashboardCardTypePieChart__legend-item-label"
            v-text="lbl"
          />
        </router-link>
      </div>
    </div>

    <FilterLegend
      v-if="card.query.query"
      :project-id="projectId"
      :query="card.query.query"
      class="DashboardCardTypePieChart__footer"
    />
    <!--div
      class="DashboardCardTypePieChart__caption"
      v-text="caption"
    /-->
  </div>
</template>

<script>
import { helpers as sharedHelpers } from '@hexway/shared-front'
import * as R from 'ramda'

import { SCORE_LOOKUP } from '../constants'
import { reportError, pushRoute } from '../helpers'
import i18n from '../i18n'

import FilterLegend from './FilterLegend'

const SCORE_FIELDS = ['totalScore', 'criticalityScore', 'probabilityScore']
const FIELD_LABELS = {
  status: i18n.t('dashboard.Status'),
  totalScore: i18n.t('dashboard.Score'),
  criticalityScore: i18n.t('dashboard.CriticalityScore'),
  probabilityScore: i18n.t('dashboard.ProbabilityScore'),
}

const GRAY_SHADES = ['#EAEEF6', '#DBE3F0', '#C4C4D3']

const emptyData = () => ({
  labels: [],
  datasets: [{
    data: [],
    backgroundColor: [],
    hoverBackgroundColor: [],
  }],
})

export default {
  name: 'DashboardCardTypePieChart',

  components: {
    VueChart: () => import(
      /* webpackChunkName: "vue-chart" */
      /* webpackPrefetch: true */
      './VueChart'
    ),

    FilterLegend,
  },

  props: {
    // cards belong to a project or cross-project dashboard (null)
    projectId: { type: String, default: null },
    // see `DashboardCard:data:model`
    card: { type: Object, required: true },
    // see `DashboardCard:computed:changes`
    changes: { type: Object, required: true },

    flat: { type: Boolean, default: false },
  },

  data: () => ({
    resolvedPatterns: {},
    canvasReady: false,
    hiddenDataIxs: [],
    hoveredIx: null,
    chartHovered: false,
  }),

  computed: {
    totalSum() {
      const { card: { value } } = this
      return Object.values(value).reduce((sum, n) => sum + n, 0)
    },

    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getLookup'](projectId)
    },

    sortedValuePairs() {
      const { card } = this
      return R.pipe(
        R.defaultTo({}),
        R.toPairs,
        R.sortWith([
          // sort from the largest value to the smallest
          R.descend(R.last),
          // then alphabetically
          R.ascend(R.compose(this.getLabel, R.head)),
        ]),
      )(card.value)
    },

    chartData() {
      const { sortedValuePairs, canvasReady, chartHovered } = this

      if (!canvasReady) return emptyData()

      let ix = 0
      return R.reduce((data, [groupingValue, counter]) => {
        // push label, counter and color to the dataset
        const lbl = this.getLabel(groupingValue, counter)

        // task#1615 no patterns
        // const color = this.getColorWithPattern(groupingValue)
        const color = this.getColor(groupingValue)

        data.labels.push(lbl)
        data.datasets[0].data.push(counter)
        data.datasets[0].backgroundColor.push(
          chartHovered && this.hoveredIx !== ix
            ? GRAY_SHADES[ix % GRAY_SHADES.length]
            : color)
        data.datasets[0].hoverBackgroundColor.push(color)

        ix++
        return data
      }, emptyData(), sortedValuePairs)
    },

    chartOptions() {
      const { card, groupBy } = this
      const self = this

      const drawTitle = async function() {
        await self.$nextTick()
        const canvas = self.$refs.chart.$el
        const canvasBounds = canvas.getBoundingClientRect()
        const chart = self.$refs.chart.$chart
        chart.ctx.textBaseline = 'middle'
        chart.ctx.textAlign = 'center'
        chart.ctx.fillStyle = '#68648B'
        chart.ctx.font = '14px Roboto, sans-serif'
        chart.ctx.fillText(self.caption, Math.round(canvasBounds.width / 2), Math.round(canvasBounds.height * 0.50))
      }

      return {
        title: {
          display: false,
          position: 'bottom',
          padding: 0,
          text: card.name,
          lineHeight: '24px',
          fontSize: 16,
          fontColor: '#3C3A52',
          fontFamily: 'Roboto, sans-serif',
          fontStyle: 'normal',
        },

        layout: {
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },

        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: '#3C3A52',
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            fontStyle: 'normal',

            boxWidth: 8,
            usePointStyle: true,
            padding: 12,
          },
        },

        tooltips: {
          enabled: false, // disabled in new design
          callbacks: {
            // https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
            label(tooltipItem, data) {
              const lbl = data.labels[tooltipItem.index] || ''
              const prefix = FIELD_LABELS[groupBy]
              return lbl ? ` ${prefix}: ${lbl} ` : ''
            },
            labelColor(tooltipItem) {
              const [groupingValue, _] = self.sortedValuePairs[tooltipItem.index]
              const color = self.getColor(groupingValue)
              return { backgroundColor: color }
            },
          },
        },

        // not working, why??
        // https://www.chartjs.org/docs/2.9.3/charts/doughnut.html#config-options
        animation: {
          animateRotate: false,
          animateScale: false,

          // this is working, though...
          // https://www.chartjs.org/docs/2.9.4/configuration/animations.html
          duration: 0,
          onComplete: drawTitle,
          onProgress: drawTitle,
        },
        // Because of the hacky hacks (see method `hideHiddenData` below)
        // animation is jumpy when hovering chart with some data hidden
        // (click legend item to hide sector, then hover doughnut)
        // animation: false,

        cutoutPercentage: 65,
        aspectRatio: 0.5,

        onClick: this.onClick,
        onHover: this.onHover,
      }
    },

    groupBy() {
      return this.card.query.groupBy
    },

    caption() {
      const { groupBy } = this
      if (groupBy === 'status') return this.$t('dashboard.Status')
      return groupBy // fixme: get from fieldsLookup
      // return ISSUE_FIELDS_SHORT_DISPLAY[groupBy] || groupBy
    },
  },

  mounted() {
    this.canvasReady = !!this.$refs.chart?.$el
  },

  updated() {
    this.canvasReady = !!this.$refs.chart?.$el
  },

  methods: {
    getColor(groupingValue) {
      const { $vuetify, issueStatusLookup, groupBy } = this

      if (groupBy === 'status') {
        const vuetifyColor =
          issueStatusLookup?.[groupingValue]?.color || 'grey'
        return sharedHelpers.vuetifyColorToHex($vuetify, vuetifyColor)
      }
      if (SCORE_FIELDS.includes(groupBy)) {
        const vuetifyColor = SCORE_LOOKUP[groupingValue]?.color
        if (vuetifyColor) {
          return sharedHelpers.vuetifyColorToHex($vuetify, vuetifyColor)
        }
      }

      reportError(`cannot infer color for ${groupBy}`)
      return '#000'
    },

    _drawCanvasPattern(parentCtx, loadedImg, backgroundColor) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = loadedImg.width
      canvas.height = loadedImg.height

      ctx.fillStyle = backgroundColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(loadedImg, 0, 0)
      ctx.stroke()

      return parentCtx.createPattern(canvas, 'repeat')
    },

    getLabel(groupingValue /* , counter */) {
      const { issueStatusLookup, groupBy } = this

      if (groupBy === 'status') {
        const statusLabel = issueStatusLookup
          ? (issueStatusLookup[groupingValue]?.displayName ?? groupingValue)
          : groupingValue
        // return `${statusLabel} ${counter}`
        return statusLabel
      }
      if (SCORE_FIELDS.includes(groupBy)) {
        // return SCORE_LOOKUP[groupingValue]?.chartLabel + ` ${counter}`
        return SCORE_LOOKUP[groupingValue]?.chartLabel
      }

      reportError(`cannot infer label for ${groupBy}`)
      return '[unknown]'
    },

    getPercent(counter, { round = false } = {}) {
      const { totalSum } = this
      const percent = counter === 0 || totalSum === 0
        ? 0
        : (counter / totalSum) * 100
      return round ? Math.round(percent) : percent
    },

    getPercentForIx(dataIx, options = {}) {
      const [_, counter] = this.sortedValuePairs[dataIx]
      return this.getPercent(counter, options)
    },

    onClick(e, activeChartElements) {
      if (this.hoveredIx == null && activeChartElements.length !== 1) {
        const { $router, projectId, card } = this
        const route = projectId
          ? {
            name: 'ProjectCardIssueList',
            params: { projectId, cardId: card.id },
          }
          : {
            name: 'CardIssueList',
            params: { cardId: card.id },
          }
        return pushRoute($router, route)
      }

      const clickedOnIx = this.hoveredIx ?? activeChartElements[0]._index
      const route = this.getRouteForPieIndex(clickedOnIx)
      pushRoute(this.$router, route)
    },

    onHover(e, activeChartElements) {
      if (activeChartElements.length !== 1) {
        // task#1615 less flickery behavior
        // this.hoveredIx = null
      } else {
        this.hoveredIx = activeChartElements[0]._index
      }
    },

    getRouteForPieIndex(ix) {
      const { projectId, card, sortedValuePairs } = this

      const [groupingValue, _] = sortedValuePairs[ix]

      return projectId
        ? {
          name: 'ProjectCardIssueList',
          params: { projectId, cardId: card.id },
          query: { groupingValue },
        }
        : {
          name: 'CardIssueList',
          params: { cardId: card.id },
          query: { groupingValue },
        }
    },

    getDataMeta(dataIx) {
      const chart = this.$refs.chart.$chart

      const datasetMeta = chart.getDatasetMeta(0)
      return datasetMeta.data[dataIx]
    },

    isDataHidden(dataIx) {
      return this.hiddenDataIxs.includes(dataIx)
    },

    toggleDataHidden(dataIx) {
      const dataMeta = this.getDataMeta(dataIx)

      dataMeta.hidden = !dataMeta.hidden
      if (dataMeta.hidden) {
        if (!this.hiddenDataIxs.includes(dataIx)) {
          this.hiddenDataIxs.push(dataIx)
        }
      } else {
        let ix = -1
        while ((ix = this.hiddenDataIxs.indexOf(dataIx)) !== -1) {
          this.hiddenDataIxs.splice(ix, 1)
        }
      }

      this.$refs.chart.$chart.update()
    },

    hideHiddenData() {
      // Hacky hacks: on redraw hidden data elements are visible again :-(
      // Hiding them again manually

      // Note: strictly speaking, this is not necessary anymore,
      // because we no longer hide data elements on legend item click.
      const { hiddenDataIxs } = this

      if (!hiddenDataIxs.length) return

      for (const dataIx of hiddenDataIxs) {
        const dataMeta = this.getDataMeta(dataIx)
        dataMeta.hidden = true
      }
      this.$refs.chart.$chart.update()
    },

    // for jest unit tests
    async _depsResolved() {
      await import('./VueChart')
    },
  },
}
</script>

<style lang="sass" scoped>
.DashboardCardTypePieChart
  display: flex
  flex-direction: column
  align-items: center
  justify-items: center
  justify-content: center
  padding: 24px 40px

  &--flat
    padding: 24px 4px

  &__title
    width: 100%
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 15px
    line-height: 21px
    color: #454359
    margin-bottom: 24px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__width-guard
    max-width: 150px
    max-height: 150px
    flex: 0 0 150px

  &__canvas
    margin-bottom: 28px

  &__legend
    margin-left: 8px

  &__legend-item
    display: flex
    align-items: center
    padding: 0 4px 4px
    white-space: nowrap
    color: inherit
    transition: 150ms ease-in
    text-decoration: inherit

  &__legend-item--muted
    filter: grayscale(1)
    opacity: .5

  &__legend-item-square
    display: inline-block
    border-radius: 2px
    width: 12px
    height: 12px
    margin-right: 6px
    border: 1px solid transparent
    transition: 150ms ease-in

  &__legend-item--hidden &__legend-item-square
    filter: grayscale(1) brightness(2)
    opacity: .5
    border-color: #666

  &__legend-item-percent
    display: inline-block
    font-size: 12px
    line-height: 20px
    letter-spacing: 0.01em
    font-weight: 500
    margin-right: 8px
    transition: 150ms ease-in

  &__legend-item-label
    font-size: 12px
    line-height: 16px
    transition: 150ms ease-in

  &__legend-item--hidden &__legend-item-label,
  &__legend-item--hidden &__legend-item-percent
    text-decoration: line-through
    opacity: .4

  &__footer
    align-self: flex-start
    margin-top: 32px

  // &__caption
  //   font-size: 13px
  //   line-height: 20px
  //   color: #8B90A0
  //   margin: 4px 0
</style>
