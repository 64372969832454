<template>
  <div
    class="DashboardCardTypeAOfB"
    :class="{
      'DashboardCardTypeAOfB--hover-a': hoverA,
      'DashboardCardTypeAOfB--hover-b': hoverB,
    }"
    @mousedown="pressed = true"
    @mouseleave="pressed = false"
    @blur="pressed = false"
  >
    <section
      class="DashboardCardTypeAOfB__name"
    >
      {{ card.name }}
      <sup
        v-if="changes.hasOwnProperty('name')"
        v-text="'*'"
      />
    </section>

    <section
      class="DashboardCardTypeAOfB__counter"
      :class="{
        'DashboardCardTypeAOfB__counter--muted':
          card.value == null ||
          (!preview && changes.hasOwnProperty('query'))
      }"
    >
      <router-link
        class="DashboardCardTypeAOfB__counter-a"
        :to="routeA"
        :event="preview ? '_disabled' : 'click'"
        v-text="card.value ? card.value.subQuery : '0'"
      />
      <router-link
        class="DashboardCardTypeAOfB__counter-b"
        :to="routeB"
        :event="preview ? '_disabled' : 'click'"
        v-text="card.value ? card.value.mainQuery : '0'"
      />
    </section>

    <div class="DashboardCardTypeCounter__progress">
      <v-progress-linear
        :value="progressPercent"
        :height="8"
        :color="color.color"
        background-color="#A9A7BF"
        background-opacity="0.3"
        rounded
      />
    </div>

    <div class="DashboardCardTypeAOfB__legend">
      <FilterLegend
        v-if="card.query.subQuery"
        :project-id="projectId"
        :query="card.query.subQuery"
        class="DashboardCardTypeAOfB__legend-a"
      />
      <FilterLegend
        v-if="card.query.mainQuery"
        :project-id="projectId"
        :query="card.query.mainQuery"
        class="DashboardCardTypeAOfB__legend-b"
      />
    </div>

    <div
      class="DashboardCardTypeAOfB__circle DashboardCardTypeAOfB__circle--a"
      :class="{
        'DashboardCardTypeAOfB__circle--active': hoverA,
        'DashboardCardTypeAOfB__circle--pressed': hoverA && pressed,
      }"
    />
    <div
      class="DashboardCardTypeAOfB__circle DashboardCardTypeAOfB__circle--b"
      :class="{
        'DashboardCardTypeAOfB__circle--active': hoverB,
        'DashboardCardTypeAOfB__circle--pressed': hoverB && pressed,
      }"
    />
    <router-link
      class="DashboardCardTypeAOfB__overlay DashboardCardTypeAOfB__overlay--a"
      :to="routeA"
      @mouseenter.native="!preview && (hoverA = true)"
      @mouseleave.native="!preview && (hoverA = false)"
    />
    <router-link
      class="DashboardCardTypeAOfB__overlay DashboardCardTypeAOfB__overlay--b"
      :to="routeB"
      @mouseenter.native="!preview && (hoverB = true)"
      @mouseleave.native="!preview && (hoverB = false)"
    />
  </div>
</template>

<script>
import * as R from 'ramda'

import { BG, COLORS, COLORS_LOOKUP } from '../constants'
import { randomChoice } from '../helpers'

import FilterLegend from './FilterLegend'

export default {
  name: 'DashboardCardTypeAOfB',

  components: {
    FilterLegend,
  },

  props: {
    // cards belong to a project or cross-project dashboard (null)
    projectId: { type: String, default: null },
    // see `DashboardCard:data:model`
    card: { type: Object, required: true },
    // see `DashboardCard:computed:changes`
    changes: { type: Object, required: true },

    // some parent (`DashboardCard.vue`) state
    edit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
  },

  data() {
    return {
      hoverA: false,
      hoverB: false,
      pressed: false,
    }
  },

  computed: {
    routeA() {
      const { projectId, card } = this

      if (!card.id) return '#'
      return projectId
        ? {
          name: 'ProjectCardIssueList',
          params: { projectId, cardId: card.id },
          query: { subQuery: 'subQuery' },
        }
        : {
          name: 'CardIssueList',
          params: { cardId: card.id },
          query: { subQuery: 'subQuery' },
        }
    },

    routeB() {
      const { projectId, card } = this

      if (!card.id) return '#'
      return projectId
        ? {
          name: 'ProjectCardIssueList',
          params: { projectId, cardId: card.id },
        }
        : {
          name: 'CardIssueList',
          params: { cardId: card.id },
        }
    },

    // card styles
    color() {
      const { card: { metaData } } = this
      const color = metaData && metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : randomChoice(COLORS)
      return COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card: { metaData } } = this
      const pattern = metaData && metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : randomChoice(Object.keys(BG))
      return BG[patternKey]
    },

    progressPercent() {
      const { value } = this.card
      const mainQuery = value?.mainQuery ?? 0
      const subQuery = value?.subQuery ?? 0
      if (mainQuery === 0 || subQuery === 0) return 0
      return subQuery / mainQuery * 100
    },
  },
}
</script>

<style lang="sass" scoped>
.DashboardCardTypeAOfB
  padding: 16px 24px
  position: relative
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  min-height: 140px
  max-height: 140px
  min-width: calc((100vw - 480px) / 3)
  max-width: calc((100vw - 480px) / 3)

  // Temporary crutch until the designer comes up with a decision on the cards
  @media only screen and (min-width: 1024px) and (max-width: 1359px)
    min-width: calc((100vw - 480px) / 2)
    max-width: calc((100vw - 480px) / 2)

  @media only screen and (min-width: 2560px)
    min-width: calc((100vw - 500px) / 4)
    max-width: calc((100vw - 500px) / 4)

  &__name
    overflow: hidden
    text-overflow: ellipsis
    font-weight: 500
    font-size: 14px
    line-height: 150%

  &__counter
    font-weight: 700
    font-size: 48px
    line-height: 56px
    text-decoration: none
    display: flex
    align-items: center
    justify-content: space-between

    &#{ & }--muted
      opacity: .3
    > a
      color: inherit
      text-decoration: none

  &__counter-a, &__counter-b
    transition: all 200ms ease-in

  &--hover-a &__counter:not(&__counter--muted) &__counter-a
    color: #007AE6

  &--hover-b &__counter:not(&__counter--muted) &__counter-b
    color: #007AE6

  &__legend
    display: flex
    justify-content: space-between
    margin-top: 4px

  &__legend-a
    margin-right: auto

  &__legend-b
    margin-left: auto

  &__legend-a + &__legend-b
    margin-left: 12px

  &__circle
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    transition: background-position 300ms ease-in-out, background-size 200ms ease-in
    pointer-events: none
    background-size: 280px 280px

    &--pressed
      background-size: 1200px 300px

    &--a
      background-image: radial-gradient(circle at 32px 12px, rgba(91, 77, 255, 0.1) 35%, transparent 36%)
      background-position: -230px -140px

    &--active#{&}--a
      background-position: 0 0

    &--b
      background-image: radial-gradient(circle at calc(100% - 32px) 12px, rgba(91, 77, 255, 0.1) 35%, transparent 36%)
      background-position: 230px -140px

    &--active#{&}--b
      background-position: 100% 0

  &__overlay
    position: absolute
    top: 0
    display: block
    width: 50%
    height: 120px

    &--a
      left: 0
      // background: rgba(255, 0, 0, .05)

    &--b
      right: 0
      // background: rgba(0, 255, 0, .05)
</style>
