<template>
  <span class="FilterLegendScoreDots">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <span
          class="FilterLegendScoreDots__wrapper"
          v-bind="attrs"
          v-on="on"
        >
          <span
            class="FilterLegendScoreDots__letter"
            v-text="captionLetter"
          />
          <span
            v-for="score in scores"
            :key="score.value"
            :score="score.value"
            class="FilterLegendScoreDots__dot"
            :class="'FilterLegendScoreDots__dot--value-' + score.value"
            :style="{ background: getColor(score.value) }"
          />
        </span>
      </template>

      <span v-text="tooltipText" />
    </v-tooltip>
  </span>
</template>

<script>
import { helpers as sharedHelpers } from '@hexway/shared-front'

import { SCORE_LOOKUP } from '../constants'

export default {
  name: 'FilterLegendScoreDots',

  props: {
    // Example: 'Probability Score' or 'Criticality Score'
    scoreTypeLabel: { type: String, required: true },

    // Examples: 'p' for probability, 'c' for criticality
    captionLetter: { type: String, required: true },

    // scores example:
    // [
    //   { value: 1, displayValue: 'Low', color: 'success', ... },
    //   ...,
    // ]
    scores: { type: Array, required: true },
  },

  computed: {
    tooltipText() {
      const { scoreTypeLabel, scores } = this
      return `${scoreTypeLabel}: ${scores.map(s => s.displayValue).join(', ')}`
    },
  },

  methods: {
    getColor(scoreValue) {
      const color = SCORE_LOOKUP[scoreValue]?.color || 'grey'
      return sharedHelpers.vuetifyColorToHex(this.$vuetify, color)
    },
  },
}
</script>

<style lang="sass" scoped>
.FilterLegendScoreDots
  display: inline-block
  color: #67648B
  font-weight: bold
  font-size: 11px
  line-height: 100%

  &__wrapper
    display: inline-flex
    background: rgba(255, 255, 255, .75)
    border-radius: 4px
    height: 15px
    align-items: center
    padding: 0 2px

  &__dot
    display: inline-block
    width: 4px
    height: 4px
    background: #8492A6
    border-radius: 100%
    transition: all 300ms ease-in
    margin-left: 2px

    & + &
      margin-left: 2px
</style>
