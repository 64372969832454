var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DashboardCardTypeAOfB",class:{
    'DashboardCardTypeAOfB--hover-a': _vm.hoverA,
    'DashboardCardTypeAOfB--hover-b': _vm.hoverB,
  },on:{"mousedown":function($event){_vm.pressed = true},"mouseleave":function($event){_vm.pressed = false},"blur":function($event){_vm.pressed = false}}},[_c('section',{staticClass:"DashboardCardTypeAOfB__name"},[_vm._v(" "+_vm._s(_vm.card.name)+" "),(_vm.changes.hasOwnProperty('name'))?_c('sup',{domProps:{"textContent":_vm._s('*')}}):_vm._e()]),_c('section',{staticClass:"DashboardCardTypeAOfB__counter",class:{
      'DashboardCardTypeAOfB__counter--muted':
        _vm.card.value == null ||
        (!_vm.preview && _vm.changes.hasOwnProperty('query'))
    }},[_c('router-link',{staticClass:"DashboardCardTypeAOfB__counter-a",attrs:{"to":_vm.routeA,"event":_vm.preview ? '_disabled' : 'click'},domProps:{"textContent":_vm._s(_vm.card.value ? _vm.card.value.subQuery : '0')}}),_c('router-link',{staticClass:"DashboardCardTypeAOfB__counter-b",attrs:{"to":_vm.routeB,"event":_vm.preview ? '_disabled' : 'click'},domProps:{"textContent":_vm._s(_vm.card.value ? _vm.card.value.mainQuery : '0')}})],1),_c('div',{staticClass:"DashboardCardTypeCounter__progress"},[_c('v-progress-linear',{attrs:{"value":_vm.progressPercent,"height":8,"color":_vm.color.color,"background-color":"#A9A7BF","background-opacity":"0.3","rounded":""}})],1),_c('div',{staticClass:"DashboardCardTypeAOfB__legend"},[(_vm.card.query.subQuery)?_c('FilterLegend',{staticClass:"DashboardCardTypeAOfB__legend-a",attrs:{"project-id":_vm.projectId,"query":_vm.card.query.subQuery}}):_vm._e(),(_vm.card.query.mainQuery)?_c('FilterLegend',{staticClass:"DashboardCardTypeAOfB__legend-b",attrs:{"project-id":_vm.projectId,"query":_vm.card.query.mainQuery}}):_vm._e()],1),_c('div',{staticClass:"DashboardCardTypeAOfB__circle DashboardCardTypeAOfB__circle--a",class:{
      'DashboardCardTypeAOfB__circle--active': _vm.hoverA,
      'DashboardCardTypeAOfB__circle--pressed': _vm.hoverA && _vm.pressed,
    }}),_c('div',{staticClass:"DashboardCardTypeAOfB__circle DashboardCardTypeAOfB__circle--b",class:{
      'DashboardCardTypeAOfB__circle--active': _vm.hoverB,
      'DashboardCardTypeAOfB__circle--pressed': _vm.hoverB && _vm.pressed,
    }}),_c('router-link',{staticClass:"DashboardCardTypeAOfB__overlay DashboardCardTypeAOfB__overlay--a",attrs:{"to":_vm.routeA},nativeOn:{"mouseenter":function($event){!_vm.preview && (_vm.hoverA = true)},"mouseleave":function($event){!_vm.preview && (_vm.hoverA = false)}}}),_c('router-link',{staticClass:"DashboardCardTypeAOfB__overlay DashboardCardTypeAOfB__overlay--b",attrs:{"to":_vm.routeB},nativeOn:{"mouseenter":function($event){!_vm.preview && (_vm.hoverB = true)},"mouseleave":function($event){!_vm.preview && (_vm.hoverB = false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }