<template>
  <v-sheet
    class="DashboardCardTypeTable"
    :class="{
      'DashboardCardTypeTable--disabled': disabled,
      'DashboardCardTypeTable--highlighted': highlighted,
    }"
    :style="{
      '--card-color': color && color.color,
      '--card-bg-img': bg && `url(${bg.image})`,
    }"
  >
    <router-link
      v-ripple="{ class: 'white--text' }"
      :to="preview ? '#' : baseRoute"
      class="DashboardCardTypeTable__title"
      :event="preview ? '_disable': 'click'"
    >
      <span v-text="card.name" />

      <FilterLegend
        :project-id="projectId"
        :query="card.query.query"
      />
    </router-link>

    <div class="DashboardCardTypeTable__table">
      <!-- first column -->
      <div
        class="DashboardCardTypeTable__column DashboardCardTypeTable__column--border-right flex-grow-0"
        @mouseleave="hoverOverRow = null"
      >
        <router-link
          :class="[
            'DashboardCardTypeTable__cell',
            'DashboardCardTypeTable__cell--bold',
            'DashboardCardTypeTable__cell--content-bottom',
            hoverOverRow === 0 ? 'DashboardCardTypeTable__cell--highlighted' : '',
            'justify-end',
          ]"
          :to="preview ? '#' : baseRoute"
          @mouseenter.native="hoverOverRow = 0"
          @mouseleave.native="hoverOverRow = null"
          v-text="columnsCaption"
        />

        <div
          :class="[
            'DashboardCardTypeTable__cell',
            'DashboardCardTypeTable__cell--tiny',
            'DashboardCardTypeTable__cell--bold',
            'justify-end',
          ]"
          v-text="rowsCaption"
        />

        <router-link
          v-for="(tableItem, rowIx) in tableItems"
          :key="`${tableItem.groupingValue}-${tableItem.yHeader}-${rowIx}`"
          :to="itemRoute(tableItem, { groupingValue: null })"
          class="DashboardCardTypeTable__cell justify-end"
          :class="{
            'DashboardCardTypeTable__cell--highlighted':
              hoverOverRow === rowIx + 1,
            'DashboardCardTypeTable__cell--caption':
              rowIx === (tableItems.length - 1),
          }"
          @mouseenter.native="hoverOverRow = rowIx + 1"
          @mouseleave.native="hoverOverRow = null"
        >
          <DashboardCardTypeTableHeaderWidget
            v-if="tableItem.groupingValue != null"
            :project-id="projectId"
            :issue-prop="card.query.yAxis"
            :prop-value="tableItem.groupingValue"
            :status-text="tableItem.yHeader"
          />
          <span
            v-else
            v-text="tableItem.yHeader"
          />
        </router-link>
      </div>

      <div
        class="DashboardCardTypeTable__right"
        :style="{ flexGrow: tableHeaders ? tableHeaders.length - 1 : 1 }"
      >
        <div
          v-for="(tableHeader, colIx) in (tableHeaders && tableHeaders.slice(1))"
          :key="tableHeader.value"
          class="DashboardCardTypeTable__column"
        >
          <router-link
            :class="[
              'DashboardCardTypeTable__cell',
              colIx === (tableHeaders.length - 2) ? 'DashboardCardTypeTable__cell--caption' : '',
              'DashboardCardTypeTable__cell--content-bottom',
              hoverOverRow === 0 ? 'DashboardCardTypeTable__cell--highlighted' : '',
            ]"
            :to="itemRoute({ groupingValue: null }, tableHeader)"
            @mouseenter.native="hoverOverRow = 0"
            @mouseleave.native="hoverOverRow = null"
          >
            <DashboardCardTypeTableHeaderWidget
              v-if="tableHeader.groupingValue != null"
              :project-id="projectId"
              :issue-prop="card.query.xAxis"
              :prop-value="tableHeader.groupingValue"
              :status-text="tableHeader.text"
            />
            <span
              v-else
              v-text="tableHeader.text"
            />
          </router-link>

          <div
            class="DashboardCardTypeTable__cell DashboardCardTypeTable__cell--tiny"
          />

          <!-- last column -->
          <template v-if="colIx === (tableHeaders.length - 2)">
            <router-link
              v-for="(tableItem, rowIx) in tableItems"
              :key="`${tableItem.groupingValue}-${tableHeader.yHeader}-${rowIx}`"
              class="DashboardCardTypeTable__cell DashboardCardTypeTable__cell--bold"
              :class="{
                'DashboardCardTypeTable__cell--highlighted':
                  hoverOverRow === rowIx + 1,
                'DashboardCardTypeTable__cell--border-top':
                  rowIx > 0,
              }"
              :to="itemRoute(tableItem, tableHeader)"
              @mouseenter.native="hoverOverRow = rowIx + 1"
              @mouseleave.native="hoverOverRow = null"
              v-text="tableItem.total || '—'"
            />
          </template>

          <template v-else>
            <router-link
              v-for="(tableItem, rowIx) in tableItems"
              :key="`${tableItem.groupingValue}-${tableHeader.yHeader}-${rowIx}`"
              class="DashboardCardTypeTable__cell"
              :class="{
                'DashboardCardTypeTable__cell--highlighted':
                  hoverOverRow === rowIx + 1,
                'DashboardCardTypeTable__cell--bold':
                  rowIx === (tableItems.length - 1),
                'DashboardCardTypeTable__cell--border-top':
                  rowIx > 0,
              }"
              :to="itemRoute(tableItem, tableHeader)"
              @mouseenter.native="hoverOverRow = rowIx + 1"
              @mouseleave.native="hoverOverRow = null"
              v-text="tableItem[tableHeader.value] || '—'"
            />
          </template>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import * as R from 'ramda'

import {
  SCORE_LOOKUP,
  COLORS_LOOKUP,
  COLORS,
  BG,
} from '../constants'
import { mapIndexed, randomChoice } from '../helpers'

import DashboardCardTypeTableHeaderWidget from './DashboardCardTypeTableHeaderWidget'
import FilterLegend from './FilterLegend'

const LOOKUPS = {
  totalScore: SCORE_LOOKUP,
  probabilityScore: SCORE_LOOKUP,
  criticalityScore: SCORE_LOOKUP,
}

export default {
  name: 'DashboardCardTypeTable',

  components: {
    DashboardCardTypeTableHeaderWidget,
    FilterLegend,
  },

  props: {
    // cards belong to a project or cross-project dashboard (null)
    projectId: { type: String, default: null },
    // see `DashboardCard:data:model`
    card: { type: Object, required: true },
    // see `DashboardCard:computed:changes`
    changes: { type: Object, required: true },

    // some parent (`DashboardCard.vue`) state
    edit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
    highlighted: { type: Boolean, default: false },
  },

  data: () => ({
    // for highlighting rows on hover
    hoverOverRow: null,
  }),

  computed: {
    fieldsLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/fieldsLookup'](projectId)
    },

    statusLookup() {
      const { $store, projectId } = this
      const lookup = projectId && $store.getters['$issueStatus/getLookup'](projectId)

      // unify status lookup with score lookups, conversion:
      // from: { name: 'new_issue', displayName: 'New issue', color: '#fff' }
      // to: { value: 'new_issue', displayValue: 'New issue', color: '#fff' }
      // where color can be one of: vuetify color, hex-string or rgb(a)-string
      return lookup && R.map(statusItem => ({
        value: statusItem.name,
        displayValue: statusItem.displayName,
        color: statusItem.color,
      }), lookup)
    },

    normalizedBody() {
      if (!this.card.value) return null

      const {
        xAxis: columnHeaderValues,
        yAxis: rowHeaderValues,
        body,
      } = this.card.value

      return rowHeaderValues.map(rowHeaderValue =>
        columnHeaderValues
          .map(columnHeaderValue => {
            const rowValues = body[columnHeaderValue] || {}
            return rowValues[rowHeaderValue] || 0
          }))
    },

    // Rotates body the following way (90 deg clockwise):
    // [
    //   [0, 1, 2],
    //   [3, 4, 5],
    // ] => [
    //   [0, 3],
    //   [1, 4],
    //   [2, 5],
    // ]
    // The reason is that it is easier to style a bunch of flex-cols
    // than an HTML-table :-/
    // bodyRotated() {
    //   const { normalizedBody } = this
    //   if (!normalizedBody) return null
    //
    //   const inputRows = normalizedBody.length // = output cols
    //   const inputCols = normalizedBody[0]?.length // = output rows
    //   if (!inputRows || !inputCols) return []
    //
    //   return Array(inputCols)
    //     .fill(null)
    //     .map((_, i) => Array(inputRows)
    //       .fill(null)
    //       .map((_, j) => normalizedBody[j][i]))
    // },

    lookups() {
      const { statusLookup: status } = this

      return {
        status, // status lookup with
        ...LOOKUPS,
      }
    },

    tableHeaders() {
      if (!this.card.value) return null
      if (!this.card.value.xAxis) return null

      const {
        lookups,
        card: {
          query: { xAxis: xAxisProp },
          value: { xAxis: xAxisHeaderValues },
        },
      } = this

      const xLookup = lookups[xAxisProp]

      return [
        {
          text: '',
          value: 'yHeader',
          align: 'left',
          groupingValue: null,
        },

        ...xAxisHeaderValues.map(xAxisValue => ({
          text: xLookup
            ? xLookup[xAxisValue]?.displayValue ?? xAxisValue
            : xAxisValue,
          value: `value-${xAxisValue}`,
          align: 'center',
          groupingValue: xAxisValue,
        })),

        {
          text: this.$t('dashboard.Total'),
          value: 'total',
          align: 'center',
          groupingValue: null,
        },
      ]
    },

    tableItems() {
      if (!this.card.value) return null
      if (!this.card.value.yAxis) return null

      const {
        lookups,
        normalizedBody: body,
        card: {
          query: { yAxis: yAxisProp },
          value: {
            xAxis: xAxisHeaderValues,
            yAxis: yAxisHeaderValues,
          },
        },
      } = this

      const yLookup = lookups[yAxisProp]

      return [
        // table body
        ...R.pipe(
          mapIndexed((row, bodyRowIx) => ({ // for each row
            // get left header from an appropriate lookup
            yHeader: ((yAxisHeaderValue) =>
              yLookup
                ? yLookup[yAxisHeaderValue]?.displayValue ?? yAxisHeaderValue
                : yAxisHeaderValue
            )(yAxisHeaderValues[bodyRowIx]),

            // values for the columns
            ...R.pipe(
              mapIndexed((value, bodyColIx) => ({ // compute intermediate value
                prop: `value-${xAxisHeaderValues[bodyColIx]}`,
                value,
              })),
              R.map(o => [o.prop, o.value]),
              R.fromPairs, // collect to an object
            )(row),

            // total for the row
            total: R.sum(row),

            // to follow links
            groupingValue: yAxisHeaderValues[bodyRowIx],
          })),
        )(body),

        // total row (the last row)
        {
          yHeader: this.$t('dashboard.Total'),

          // sums for every column
          ...R.pipe(
            mapIndexed((val, bodyColIx) => ({
              prop: `value-${val}`,
              value: R.sum(body.map(row => row[bodyColIx])),
            })),
            R.map(o => [o.prop, o.value]),
            R.fromPairs,
          )(xAxisHeaderValues),

          // grand total (bottom right cell)
          total: R.sum(body.map(R.sum)),

          groupingValue: null,
        },
      ]
    },

    rowsCaption() {
      const { card, fieldsLookup } = this
      const prop = card.query.yAxis
      if (prop === 'status') return this.$t('dashboard.Status')
      return fieldsLookup?.[prop]?.displayName || prop
    },

    columnsCaption() {
      const { card, fieldsLookup } = this
      const prop = card.query.xAxis
      if (prop === 'status') return this.$t('dashboard.Status')
      return fieldsLookup?.[prop]?.displayName || prop
    },

    baseRoute() {
      const { projectId, card } = this

      if (!card.id) return {}
      return projectId
        ? {
          name: 'ProjectCardIssueList',
          params: { projectId, cardId: card.id },
          query: {},
        }
        : {
          name: 'CardIssueList',
          params: { cardId: card.id },
          query: {},
        }
    },

    // card styles
    color() {
      const { card: { metaData } } = this
      const color = metaData && metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : randomChoice(COLORS)
      return COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card: { metaData } } = this
      const pattern = metaData && metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : randomChoice(Object.keys(BG))
      return BG[patternKey]
    },
  },

  methods: {
    itemRoute(item, header) {
      const { baseRoute, preview } = this

      if (preview) return '#'

      if (header.groupingValue == null && item.groupingValue == null) {
        return baseRoute
      }

      const json = JSON.stringify.bind(JSON)
      return R.mergeDeepRight(baseRoute, {
        query: {
          groupingValue:
            `${json(header.groupingValue)}~${json(item.groupingValue)}`,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.DashboardCardTypeTable
  overflow: hidden
  display: flex
  flex-direction: column
  height: 100%
  color: #67648B
  background: transparent

  &__title
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    text-decoration: none
    color: inherit
    padding-left: 32px
    padding-right: 24px
    height: 59px
    font-size: 16px
    font-weight: 500
    line-height: 120%
    letter-spacing: normal !important
    border-radius: 4px 4px 0 0
    transition: all 100ms ease-out 75ms
    position: relative
    // box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05)

  &__table
    flex: 1
    display: flex
    flex-wrap: nowrap
    overflow: auto
    // min-height: 500px // fixme

  &__right
    flex: 1 0 auto
    display: flex

  &__column
    flex: 1 0 auto
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    padding-bottom: 26px
    transition: 200ms ease-out
    overflow: hidden
    text-overflow: ellipsis

    &:hover
      background: rgba(91, 77, 255, 0.1)

    &--border-right
      $gradient: linear-gradient(to left, rgba(0, 0, 0, .2) 0, transparent 1px)
      background: $gradient no-repeat top 48px right

      &:hover
        background: $gradient no-repeat top 48px right, rgba(91, 77, 255, 0.1)

  &--right &__column
    flex: 1 1 auto

  &__column:hover &__cell--border-top
    border-top: 1px solid transparent

  &__cell
    flex: 0 0 48px
    padding: 0 16px
    display: flex
    align-items: center
    justify-content: center
    color: inherit
    text-decoration: inherit
    background-repeat: repeat
    transition: 200ms ease-out
    font-size: 13px
    line-height: 150%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &:first-child
      box-shadow: 0 1px 2px rgba(0, 0, 0, .05), 0 0 4px rgba(0, 0, 0, .12)

    &--highlighted:not(&--tiny)
      background: linear-gradient(to right, rgba(91, 77, 255, 0.1), rgba(91, 77, 255, 0.1)) no-repeat

    &--bold
      font-weight: bold

    &--tiny
      flex: 0 0 32px
      align-items: flex-end

    &--caption
      color: #8492A6
      font-weight: 500
      font-size: 12px
      line-height: 150%
      letter-spacing: 0.03em

    &--content-bottom
      padding-bottom: 8px
      align-items: flex-end

    &--border-top
      border-top: 1px solid rgba(0, 0, 0, .1)

    &:hover:not(&--tiny)
      color: white

    &:hover:not(&--tiny)
      background: #67648B !important
      // background: var(--card-color, #67648B) !important
      // background-image: var(--card-bg-img) !important
      box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 1px 2px rgba(0, 0, 0, .12)
      border-radius: 4px
</style>
